.gold-border-top-bottom {
  border-bottom: 3px solid;
  border-top: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(45deg, #f1d563, #976c2b);
}

.gold-border-button {
  font-weight: 700 !important;
  background-color: transparent !important;
  background-image: linear-gradient(180deg, #292929 0%, #020202 100%);
  border-style: solid !important;
  border-color: #f2c556 !important;
}

.gold-border {
  border-bottom: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(45deg, #f1d563, #976c2b);
}

.gold-text {
  background-image: linear-gradient(#eec85c, #a5732c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gold-text-alt {
  background-image: linear-gradient(#a5732c, #eec85c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-bg {
  background-color: transparent;
  background-image: linear-gradient(180deg, #3a3a3a 0%, #1b1b1b 100%);
}

.card-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.6);
}

.gold-border-left {
  border-style: solid;
  border-width: 0px 3px 0px 0px;
  border-color: #e2bb54;
  box-shadow: 10px 0px 20px 0px #000000;
  /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
  z-index: 10;
}

.container-no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container-no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.gradient-bg-border {
  background: rgb(172, 120, 54);
  background: -moz-linear-gradient(0deg, rgba(172, 120, 54, 1) 0%, rgba(232, 197, 91, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(172, 120, 54, 1) 0%, rgba(232, 197, 91, 1) 100%);
  background: linear-gradient(0deg, rgba(172, 120, 54, 1) 0%, rgba(232, 197, 91, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ac7836",endColorstr="#e8c55b",GradientType=1);
  transform: skew(-21deg);
  padding: 3px;
  border-radius: 2px;
}

.gradient-bg-inner {
  text-align: center;
  background-color: transparent !important;
  background-image: linear-gradient(180deg, #292929 0%, #020202 100%);
}

.gradient-bg-inner .MuiTypography-root {
  transform: skew(21deg);
}

.custom-btn-red {
  border-width: 2px !important;
  border-color: rgb(212, 37, 42) !important;
  background: rgb(212, 37, 42);
  background: -moz-radial-gradient(circle, rgba(212, 37, 42, 1) 0%, rgba(121, 19, 22, 1) 80%);
  background: -webkit-radial-gradient(circle, rgba(212, 37, 42, 1) 0%, rgba(121, 19, 22, 1) 80%);
  background: radial-gradient(circle, rgba(212, 37, 42, 1) 0%, rgba(121, 19, 22, 1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d4252a",endColorstr="#791316",GradientType=1);
}

.custom-btn-green {
  border-width: 2px !important;
  border-color: rgb(0, 241, 73) !important;
  background: rgb(0, 241, 73);
  background: -moz-radial-gradient(circle, rgba(0, 241, 73, 1) 0%, rgba(0, 132, 40, 1) 80%);
  background: -webkit-radial-gradient(circle, rgba(0, 241, 73, 1) 0%, rgba(0, 132, 40, 1) 80%);
  background: radial-gradient(circle, rgba(0, 241, 73, 1) 0%, rgba(0, 132, 40, 1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00f149",endColorstr="#008428",GradientType=1);
}

.custom-btn-blue {
  border-width: 2px !important;
  border-color: rgb(35, 41, 197) !important;
  background: rgb(35, 41, 197);
  background: -moz-radial-gradient(circle, rgba(35, 41, 197, 1) 0%, rgba(19, 22, 107, 1) 80%);
  background: -webkit-radial-gradient(circle, rgba(35, 41, 197, 1) 0%, rgba(19, 22, 107, 1) 80%);
  background: radial-gradient(circle, rgba(35, 41, 197, 1) 0%, rgba(19, 22, 107, 1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2329c5",endColorstr="#13166b",GradientType=1);
}

.custom-btn-yellow {
  border-width: 2px !important;
  border-color: rgb(227, 189, 37) !important;
  background: rgb(227, 189, 37);
  background: -moz-radial-gradient(circle, rgba(227, 189, 37, 1) 0%, rgba(111, 93, 19, 1) 80%);
  background: -webkit-radial-gradient(circle, rgba(227, 189, 37, 1) 0%, rgba(111, 93, 19, 1) 80%);
  background: radial-gradient(circle, rgba(227, 189, 37, 1) 0%, rgba(111, 93, 19, 1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e3bd25",endColorstr="#6f5d13",GradientType=1);
}

.referral-btn {
  box-shadow: 0px 0px 0px 2px rgba(227, 189, 37, 1);
  -webkit-box-shadow: 0px 0px 0px 2px rgba(227, 189, 37, 1);
  -moz-box-shadow: 0px 0px 0px 2px rgba(227, 189, 37, 1);
  color: #fff5f4 !important;
  text-shadow: -2px 1px 1px rgba(0, 0, 0, 0.6);
  /* -webkit-text-fill-color: white; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.4); */
}

.referral-text {
  color: #fff5f4 !important;
  text-shadow: -2px 1px 1px rgba(0, 0, 0, 1) !important;
  /* -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.4); */
}

iframe {
  right: 4.5vw !important;
  bottom: 25vw !important;
}

/* Prevent carousel copying height of current image */
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

:root {
  --border-width: 3px;
}

.gradient-border-animated {
  position: relative;
  /* background: black; */
  transform-style: preserve-3d;
  overflow: visible;
  border: var(--border-width) solid transparent;
  border-radius: var(--border-width);
}

.gradient-border-animated .mask {
  position: absolute;
  inset: calc(0px - var(--border-width) - 0px);
  border: calc(0px + var(--border-width) + 0px) solid #0000;
  border-radius: calc(0px + var(--border-width) + 0px + var(--border-width));
  -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  
}
.gradient-border-animated .mask::before {
  content: "";
  position: absolute;
  inset: calc(0px - var(--border-width));
  border-radius: var(--border-width);
  /* top: calc(-1 * var(--border-width)); */
  /* left: calc(-1 * var(--border-width)); */
  z-index: -1;
  /* width: calc(100% + var(--border-width) * 2); */
  /* height: calc(100% + var(--border-width) * 2); */
  background: linear-gradient(
    60deg,
    hsl(57, 96%, 46%),
    hsl(65, 100%, 50%),
    hsl(63, 87%, 60%),
    hsl(63, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(53, 100%, 50%),
    hsl(55, 100%, 50%),
    hsl(66, 100%, 78%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 1s alternate infinite;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 2px 0px rgba(255, 251, 0, 1);
  }
  100% {
    box-shadow: 0 0 2px 10px rgba(255, 251, 0, 0);
  }
}